// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const { version } = require('../../package.json');
export const environment = {
  uat: false,
  production: false,
  googleTagManagerCode: '',
  config: {
    name: 'kaust-customer',
    title: 'Thya Technology',
    version: version,
    apiUrl: {
      cmsUrl: 'https://stg-cms.devcl.net/',
      backendUrl: 'https://stg-backend.devcl.net/api/v1/',
      frontendUrl: 'https://stg-customer-angular.devcl.net/',
      tawkToUrl:  'tawk.to',
      propertyId: '630cb22454f06e12d891888c',
      widgetId: '1gbkpfo27',
      inferencePredictServerUrl: 'https://infer-01.devcl.net/api/predict',
    },
    environment: 'staging',
    googleMapAPIKey: 'AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8',
    accessTokenMapBox: 'pk.eyJ1IjoiYWRtaW4yMjIxOTU1NSIsImEiOiJjbHE2Y3J5MW4wczg3MmpwYWxxZDFuanl5In0.IdgYzRDCWidGizy_JiJX4Q',
    styleMapBox: 'mapbox://styles/admin22219555/cm60k7rom004d01sf2cm7dsw5',
    paymentAccessCode: 'XYdGxMIi0QHgesBQkEcN',
    paymentMerchantIdentifier: 'ec8ffc16',
    paymentRequestPhrase: '95RZKp3PdNxF9yIK.RZkTVpk',
    paymentResponsePhrase: '05GPjHfAlt3zs6WfFQB5XZ]-',
  },
  s3BucketConfig: {
    public: 'devcl-stg-web-app-public-assets',
    cloudFrontCDN: 'pub-assets-stg.devcl.net',
    private: 'devcl-stg-web-app-private-assets',
  },
};
